import { Dropzone } from "dropzone";
import { DOMUtils } from "../dom-utils"

export class UploaderInput {
  constructor(element) {
    Dropzone.autoDiscover = false;
    this._element = element
    this._dropzone = null;
  }

  isAttached() {
    const attachableType = this._element.getAttribute("data-attachable-type")
    const attachableId = this._element.getAttribute("data-attachable-id")

    return this._element?.isConnected &&
      this._attachableType == attachableType &&
      this._attachableId == attachableId
  }

  create() {
    this._attachableType = this._element.attributes["data-attachable-type"].value;
    this._attachableId = this._element.attributes["data-attachable-id"].value;
    this._containerId = this._element.attributes["data-container-id"].value;

    let dropzone = new Dropzone(
      this._element,
      {
        timeout: 0,
        hiddenInputContainer: this._containerId
      }
    );

    let csrf = document.querySelector("meta[name='csrf-token']").content;

    dropzone.on("sending", (file, xhr, formData) => {
      formData.append("attachable_type", this._attachableType);
      formData.append("attachable_id", this._attachableId);
      xhr.setRequestHeader("X-CSRF-Token", csrf);
    });
    dropzone.on("success", (file, responseText, e) => {
      const action = responseText.action
        const parser = new DOMParser()
        const doc = parser.parseFromString(responseText.data, "text/html")

      if(action == "replace") {
        const target = document.querySelector(responseText.target)
        const html = doc.querySelector(responseText.target)
        DOMUtils.replace(target, html , true )
      } else {
        const html = doc.body.innerHTML
        DOMUtils.appendOrReplace(this._containerId, responseText.target, html)
      }
      const container = document.querySelector(this._containerId)
      container.querySelectorAll('img[data-preview-url]').forEach((img) => {
        if(!img.src) {
          img.src = img.dataset.previewUrl;
        }
      });
    });

    this._dropzone = dropzone;
  }

  destroy() {
    if(!this._element)
      return

    const attachableType = this._element.getAttribute("data-attachable-type")
    const attachableId = this._element.getAttribute("data-attachable-id")

    if(!this.isAttached()) {
      this._dropzone.destroy()
      this._dropzone = null
      this._element = null
      this._attachableType = null
      this._attachableId = null
    }
  }
}
