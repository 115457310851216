class OsDetector {
  static isMacOs() {
    navigator.appVersion.toLowerCase().indexOf('mac') != -1
  }

  static flagOs() {
    if(this.isMacOs())
      $('body').addClass('macos')
  }
}

export { OsDetector }
