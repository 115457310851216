import "./jquery"
// Import Popper.js
import Popper from 'popper.js';

// Expose Popper globally so Bootstrap can access it
window.Popper = Popper;

require("jquery-ui/dist/jquery-ui")
require("bootstrap")
require("leaflet")
require("leaflet-ant-path")
require("./looper-base/turbolinks-hacks")
require("dropzone")
import Chart from 'chart.js/auto';
window.Chart = Chart;

import "@fortawesome/fontawesome-free/css/all.css";
import "flatpickr/dist/flatpickr.css";
import "select2/dist/css/select2.css";
import "dropzone/dist/dropzone.css";
import "iv-viewer/dist/iv-viewer.css";
import 'leaflet/dist/leaflet.css';

require("./looper-base/theme")
