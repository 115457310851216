import select2 from "select2"

window.select2 = select2();



class SelectInput {
  constructor(element) {
    this.element = element
  }

  start() {
    this.create()
    this.attachEventHandlers()
  }

  attachEventHandlers() {
    $(this.element).on('select2:open', this.onSelect2Open);
  }

  isAttached() {
    return this.element?.isConnected
  }

  formatResultItem(item) {
    let prefix = "";
    if (item.level) {
      for(let i = 0; i < item.level; i++) {
        prefix += "  ";
      }
    }
    let text = prefix + item.text;
    if(!item.id) {
      return $("<b>"+ text + "</b>");
    }
    return text;
  }

  formatSelectedItem(item) {
    if(item.selected_text) {
      return item.selected_text;
    }
    return item.text;
  }

  create(element) {
    const data = this.element.dataset
    let options = {}
    options["toggle"] = data["toggle"]
    options["placeholder"] = data["placeholder"]
    options["allowClear"] = data["allowClear"]
    options["templateResult"] = (item) => this.formatResultItem(item);
    options["templateSelection"] = (item) => this.formatSelectedItem(item);
    const modals = document.querySelectorAll('.modal');
    modals.forEach((m) => {
      if(m.contains(this.element)) {
        options["dropdownParent"] = m;
      }
    })

    if (data.ajaxUrl) {
      options["ajax"] = {
        url: data["ajaxUrl"],
        dataType: "json",
        data: (params) => this.prepareData(this.element, params),
        processResults: (data) => this.processResults(data)
      }
    }

    $(this.element).select2(options)
  }

  prepareData(element, params) {
    let opts = {
      q: params.term,
      page: params.page,
      _type: "query"
    };

    $(element.form).serializeArray().forEach((o) => {
      opts[o.name] = o.value;
    });

    return opts;
  }

  processResults(data) {
    if (data.results === undefined) {
      return {
        results: data
      };

    } else {
      return data;
    }
  }

  destroy() {
    if(!this._element)
      return

    $(this.element).off('select2:open', this.onSelect2Open);
    $(this.element).select2('destroy')
    this.element = null
  }
  // hack to fix jquery 3.6 focus security patch that bugs auto search
  // in select-2
  onSelect2Open(evt) {
    const searchField = document.querySelector(
      '.select2-container--open .select2-search__field'
    )
    searchField?.focus()
  }

}

class SelectInputs {

  static start() {
    if(!window.selectInputs) {
      window.selectInputs = new this()
      window.selectInputs.start()
    }
  }

  constructor() {
    this._selectInputs = []
  }

  start() {
    this.init()
    this.attachEventListeners()
  }

  init() {
    // responsive setting
    $.fn.select2.defaults.set('width', '100%')

    this.cleanup()
    this.create()
  }

  attachEventListeners() {
    $(document).on("turbo:load", () => this.init() )
    $(document).on("turbo:submit-end", () => {
      for(const si of this._selectInputs) {
        si.destroy()
      }
      this._selectInputs = []
    })
    $(document).on("turbo:before-render", () => {
      for(const si of this._selectInputs) {
        si.destroy()
      }
      this._selectInputs = []
    })
    $(document).on("hidden.bs.modal", (evt) => {
      const modal = evt.target
      modal.querySelectorAll(`select[data-toggle="select2"]`).forEach((e) => {
        const selectInput = this._selectInputs.find((s) => s.element == e)
        selectInput?.destroy()
      })
      this.cleanup()
    });
    // hack to fix the keyboard shortcut for modals
    window.addEventListener('keydown', (e) => {
      const isEscPressed = (e.keyCode == 27);
      if (!isEscPressed)
        return;

      const isSelect2Open = $('.select2-container--open').length;
      if (!isSelect2Open)
        return;

      e.stopPropagation();
      $('.select2-hidden-accessible').select2('close');

      return;
    }, true);
  }

  cleanup() {
    this._selectInputs = this._selectInputs.filter((si) => {
      if(si.isAttached())
        return true

      si.destroy()
      return false
    })
  }

  create() {
    const elements = document.querySelectorAll('select[data-toggle="select2"]')
    for(const element of elements) {
      if(this._selectInputs.find((s) => s.element == element))
        continue

      const selectInput = new SelectInput(element)
      selectInput.start()
      this._selectInputs.push(selectInput)
    }
  }
}
export { SelectInputs }
