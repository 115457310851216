import { Slider } from './slider';
import { Pinch } from './pinch';
import { DoubleTap } from './double-tap';
import { Scroller } from './scroller';

export {
  Slider,
  Pinch,
  DoubleTap,
  Scroller
};
